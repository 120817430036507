import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DatePipe } from '@angular/common';
import {DevoxxSharedLibsModule} from './shared-libs.module';
import {DevoxxSharedCommonModule} from './shared-common.module';

@NgModule({
    imports: [
      DevoxxSharedLibsModule,
      DevoxxSharedCommonModule
    ],
    declarations: [

    ],
    providers: [
        DatePipe
    ],
    exports: [
        DevoxxSharedCommonModule,
        DatePipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]

})
export class DevoxxSharedModule {}
