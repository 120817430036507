import { Routes } from '@angular/router';

import { EventDetailComponent } from './event-detail.component';

export const eventRoute: Routes = [
    {
        path: 'event/:id',
        component: EventDetailComponent,
        data: {
            pageTitle: 'voxxedApp.event.home.title'
        },
    }
];
