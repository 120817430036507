import { Routes } from '@angular/router';

import { CategoryComponent } from './category.component';
import { CategoryDetailComponent } from './category-detail.component';

export const contactRoute: Routes = [
    {
        path: 'events',
        component: CategoryComponent,
        data: {
            pageTitle: 'voxxedApp.category.home.title'
        },
    }, {
        path: 'events/:name',
        component: CategoryDetailComponent,
        data: {
            pageTitle: 'voxxedApp.category.home.title'
        }
    }
];
