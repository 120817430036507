import { ISpeaker } from '../../entities/speaker';

export const getSpeakerImageURL = (speaker: ISpeaker, isLarge: boolean): string => {
  if (speaker != null &&
      speaker.avatarURL != null) {
    if (speaker.avatarURL.includes('voxxeddays')) {
      let strings;
      if (speaker.avatarURL.endsWith('.png')) {
        strings = speaker.avatarURL.split('.png');
      } else {
        strings = speaker.avatarURL.split('.jpg');
      }
      if (isLarge) {
        return strings[0] + '_large.jpg';
      } else {
        return strings[0] + '_medium.jpg';
      }
    } else {
      return speaker.avatarURL;
    }
  }
};
