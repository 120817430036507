import { Component, OnInit } from '@angular/core';

import { Speaker } from './speaker.model';
import { SpeakerService } from './speaker.service';
import { WindowRef } from '../../home/WindowRef';
import {TOTAL_COUNT} from '../../shared/constants/param.constants';

@Component({
    selector: 'app-speaker',
    templateUrl: './speaker.component.html'
})
export class SpeakerComponent implements OnInit {

    speakers: Speaker[];
    searchText: string;
    totalItems: any;
    queryCount: any;

    constructor(
        private speakerService: SpeakerService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loadAll();
    }

    loadAll() {
        if (this.searchText) {
            this.findSpeakers();
            return;
        }

        this.speakerService.query({size: 12}).subscribe(
            res  => this.speakers = res.body,
            res => this.onError(res.json)
        );
    }

    isValid(index: number) {
        return this.speakers != null &&
               this.speakers.length > index;
    }

    getSpeaker(index: number) {
        if (this.isValid(index)) {
            return this.speakers[index];
        }
    }

    findSpeakers() {
        this.speakerService.search( {
            query: this.searchText
        }).subscribe(
            res => this.onSuccess(res.body, res.headers),
            res => this.onError(res.json)
        );
    }

    private onSuccess(data, headers) {
        this.totalItems = headers.get(TOTAL_COUNT);
        this.queryCount = this.totalItems;
        this.speakers = data;
    }

    private onError(error) {
        console.log(error);
    }
}
