import { Component, OnInit } from '@angular/core';

import { WindowRef } from '../../home/WindowRef';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html'
})
export class CategoryComponent implements OnInit {

    constructor(
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();
    }
}
