import { Routes } from '@angular/router';

import { AboutComponent } from './about.component';

export const aboutRoute: Routes = [
    {
        path: 'about',
        component: AboutComponent,
        data: {
            pageTitle: 'voxxedApp.about.home.title'
        }
    }
];
