import { Directive, Input} from '@angular/core';

@Directive({
    selector: '[appActiveMenu]'
})
export class ActiveMenuDirective {
    @Input() jhiActiveMenu: string;

    constructor() {}
}
