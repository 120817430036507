import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
    EventService,
    EventDetailComponent,
    eventRoute,
} from './';
import {HttpClientModule} from '@angular/common/http';
import {DevoxxSharedModule} from '../../shared/shared.module';

const ENTITY_STATES = [
    ...eventRoute,
];

@NgModule({
    imports: [
      DevoxxSharedModule,
      HttpClientModule,
      RouterModule.forChild(ENTITY_STATES)
    ],
    declarations: [
      EventDetailComponent,
    ],
    entryComponents: [
    ],
    providers: [
      EventService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DevoxxEventModule {}
