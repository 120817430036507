import { Route } from '@angular/router';

import { ArchiveComponent } from './archive.component';

export const archiveRoute: Route = {
    path: 'archive',
    component: ArchiveComponent,
    data: {
        pageTitle: 'voxxedApp.category.home.title'
    }
};
