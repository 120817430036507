import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { DevoxxSpeakerModule } from './speaker/speaker.module';
import { DevoxxEventModule } from './event/event.module';
import { DevoxxAboutModule } from './about/about.module';
import { DevoxxCategoryModule } from './category/category.module';
import { DevoxxArchiveModule } from './archive/archive.module';
import { DevoxxContactModule } from './contact/contact.module';
/* jhipster-needle-add-entity-module-import - JHipster will add entity modules imports here */

@NgModule({
    imports: [
      DevoxxSpeakerModule,
      DevoxxEventModule,
      DevoxxAboutModule,
      DevoxxCategoryModule,
      DevoxxArchiveModule,
      DevoxxContactModule,
        /* jhipster-needle-add-entity-module - JHipster will add entity modules here */
    ],
    declarations: [],
    entryComponents: [],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DevoxxEntityModule {}
