import { Component, OnInit } from '@angular/core';

import { VERSION } from '../../app.constants';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: [
        'navbar.css'
    ]
})
export class NavbarComponent implements OnInit {

    isNavbarCollapsed: boolean;
    version: string;

    constructor(
    ) {
        this.version = VERSION ? 'v' + VERSION : '';
        this.isNavbarCollapsed = true;
    }

    ngOnInit() {
    }
}
