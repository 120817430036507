import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WindowRef } from './WindowRef';
import { DevoxxSharedModule } from '../shared/shared.module';
import { HOME_ROUTE } from './home.route';
import { HomeComponent } from './home.component';

@NgModule({
    imports: [
      DevoxxSharedModule,
      RouterModule.forChild([ HOME_ROUTE ])
    ],
    declarations: [
      HomeComponent,
    ],
    entryComponents: [
    ],
    providers: [
      WindowRef
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DevoxxHomeModule {}
