import { Injectable } from '@angular/core';

import { ISpeaker, Speaker } from './speaker.model';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from '../../app.constants';
import { IPresentation, Presentation } from '../presentation';
import { createRequestOption } from '../../shared/util/request-util';

type EntityResponseType = HttpResponse<ISpeaker>;
type EntityArrayResponseType = HttpResponse<ISpeaker[]>;

@Injectable()
export class SpeakerService {

    private resourceUrl = SERVER_API_URL + 'voxxed/speakers';

    constructor(private http: HttpClient) { }

    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<ISpeaker>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    query(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
          .get<ISpeaker[]>(`${this.resourceUrl}/champions`, { params: options, observe: 'response' });
    }

    search(req?: any): Observable<EntityArrayResponseType> {
        const options = createRequestOption(req);
        return this.http
          .get<Speaker[]>(`${this.resourceUrl}/search`, { params: options, observe: 'response' });
    }

    findPresentations(id: number): Observable<HttpResponse<IPresentation[]>> {
        return this.http
          .get<Presentation[]>(`${this.resourceUrl}/${id}/presentations`, { observe: 'response' });
    }
}
