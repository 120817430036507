import { Routes } from '@angular/router';

import { ContactComponent } from './contact.component';

export const contactRoute: Routes = [
    {
        path: 'contact',
        component: ContactComponent,
        data: {
            pageTitle: 'voxxedApp.contact.home.title'
        }
    }
];
