import { Routes } from '@angular/router';

import { SpeakerComponent } from './speaker.component';
import { SpeakerDetailComponent } from './speaker-detail.component';

export const speakerRoute: Routes = [
    {
        path: 'speakers',
        component: SpeakerComponent,
        data: {
            pageTitle: 'voxxedApp.speaker.home.title'
        }
    }, {
        path: 'speaker/:id',
        component: SpeakerDetailComponent,
        data: {
            pageTitle: 'voxxedApp.speaker.home.title'
        }
    }
];
