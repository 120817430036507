import {IPresentation} from '../presentation';

export interface ISpeaker {
  id?: number;
  firstName?: string;
  lastName?: string;
  bio?: any;
  company?: string;
  avatarURL?: string;
  twitterHandle?: string;
  presentations?: IPresentation[];
}
export class Speaker implements ISpeaker {
  constructor(
    public id?: number,
    public firstName?: string,
    public lastName?: string,
    public bio?: any,
    public company?: string,
    public avatarURL?: string,
    public twitterHandle?: string,
    public presentations?: IPresentation[],
  ) {
  }
}
