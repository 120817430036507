import { Component, OnInit } from '@angular/core';

import { SpeakerService } from '../speaker';
import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
})
export class ArchiveComponent implements OnInit {

    pastEvents: Event[];

    constructor(
        private eventService: EventService,
        private speakerService: SpeakerService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loadPastEvents({size: 20});
    }

    loadPastEvents(req?: any) {
        this.eventService.pastEvents(req).subscribe(
          res => this.pastEvents = res.body,
          res => console.log(res.json)
        );
    }

    getEvent(item: number) {
        if (this.pastEvents != null) {
            return this.pastEvents[item];
        } else {
            return null;
        }
    }
}
