import { Component, Input } from '@angular/core';
import { ISpeaker } from '../../entities/speaker';
import { getSpeakerImageURL } from '../util/speaker-image-util';

@Component({
    selector: 'app-speaker-item',
    templateUrl: 'speakerItem.component.html'
})
export class SpeakerItemComponent {

    @Input() speaker: ISpeaker;
    @Input() isLarge: false;

    constructor() {
    }

    getSpeakerAvatarURL() {
      return getSpeakerImageURL(this.speaker, this.isLarge);
    }
}
