import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IEvent } from './event.model';
import { SERVER_API_URL } from '../../app.constants';
import { createRequestOption } from '../../shared/util/request-util';

type EntityResponseType = HttpResponse<IEvent>;
type EntityArrayResponseType = HttpResponse<IEvent[]>;

@Injectable()
export class EventService {

    private resourceUrl = SERVER_API_URL + 'public/events';

    constructor(private http: HttpClient) {
    }

    futureDevoxxEvents(req?: any): Observable<EntityArrayResponseType> {
      const options = createRequestOption(req);
      return this.http
        .get<IEvent[]>(`${this.resourceUrl}/future/devoxx`, { params: options, observe: 'response' });
    }

    futureVoxxedEvents(req?: any): Observable<EntityArrayResponseType> {
      const options = createRequestOption(req);
      return this.http
        .get<IEvent[]>(`${this.resourceUrl}/future/voxxed`, { params: options, observe: 'response' });
    }

    find(id: number): Observable<EntityResponseType> {
      return this.http
        .get<IEvent>(`${this.resourceUrl}/${id}`, { observe: 'response' });
    }

    pastEvents(req?: any): Observable<EntityArrayResponseType> {
      const options = createRequestOption(req);
      return this.http
        .get<IEvent[]>(`${this.resourceUrl}/past`, { params: options, observe: 'response' });
    }
}
