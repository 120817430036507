import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';

import { archiveRoute } from './archive.route';
import { ArchiveComponent } from './archive.component';
import {DevoxxSharedModule} from '../../shared/shared.module';

@NgModule({
    imports: [
      DevoxxSharedModule,
      RouterModule.forRoot([archiveRoute], { useHash: true })
    ],
    declarations: [
        ArchiveComponent,
    ],
    entryComponents: [
        ArchiveComponent,
    ],
    providers: [
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DevoxxArchiveModule {}
