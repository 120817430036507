import { Component, Input } from '@angular/core';
import { Event } from '../../entities/event';
import * as moment from 'moment-timezone';

@Component({
    selector: 'app-event-item',
    templateUrl: 'eventItem.component.html'
})
export class EventItemComponent {

    @Input() event: Event;

    @Input() isMedium: true;
    @Input() isSmall: false;
    @Input() isArchived: false;
    @Input() clearFix: false;

    isChrome: boolean = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    isOpera: boolean = /Opera/.test(navigator.userAgent);

    constructor() {}

    getEventDate() {
      const DATE_FORMAT = 'D MMM YYYY';
      const DAY_FORMAT = 'D';
      const MONTH_FORMAT = 'MMM';
      const TIMEZONE = 'Europe/Brussels';

      const fromDate = moment(this.event.fromDate).tz(TIMEZONE);
      const toDate = moment(this.event.toDate).tz(TIMEZONE);

      if (fromDate.days() === toDate.days()) {
          return fromDate.format(DATE_FORMAT).toString();
      } else {
        if (fromDate.format(MONTH_FORMAT) !== toDate.format(MONTH_FORMAT)) {
          return fromDate.format(DAY_FORMAT).toString() +
                 ' ' +
                 fromDate.format(MONTH_FORMAT).toString() +
                 ' - ' + toDate.format(DATE_FORMAT).toString();
        } else {
          return fromDate.format(DAY_FORMAT).toString() + ' - ' + toDate.format(DATE_FORMAT).toString();
        }
      }
    }

    getEventImageURL(isSmall: boolean) {
      if (this.event.imageURL.startsWith('https://devoxxian-image-thumbnails')) {
        // The NEW image thumbnail logic using AWS lamba
        return this.event.imageURL
      } else {
        // The OLD image thumbnail logic using ReThumb out-of-use service
        let extension = '.jpg';
        if (this.isChrome || this.isOpera) {
          extension = '.webp';
        }
        if (this.event.imageURL.length > 0) {

          let strings;

          if (this.event.imageURL.endsWith('.png')) {
            strings = this.event.imageURL.split('.png');
          } else if (this.event.imageURL.endsWith('.jpg')) {
            strings = this.event.imageURL.split('.jpg');
          }

          if (isSmall) {
            return strings[0] + '_medium' + extension;
          } else {
            return strings[0] + '_large' + extension;
          }
        }
      }
    }

    getCategoryColor() {
        if (this.event.eventCategory.toString() === 'DEVOXX') {
            return '#dd9933';
        } else {
            return '#27b1db';
        }
    }
}
