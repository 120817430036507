import { Component, OnInit } from '@angular/core';

import { EventService, Event } from '../event';
import { WindowRef } from '../../home/WindowRef';
import { Speaker, SpeakerService } from '../speaker';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit {

    events: Event[];
    speakers: Speaker[] = [];

    constructor(
        private eventService: EventService,
        private speakerService: SpeakerService,
        private winRef: WindowRef
    ) {
    }

    ngOnInit() {
        this.winRef.nativeWindow.webFlow.destroy();
        this.winRef.nativeWindow.webFlow.ready();

        this.loadFutureEvents();
        this.loadSpeakers();
    }

    loadFutureEvents() {
        this.eventService.futureDevoxxEvents().subscribe(
          res => this.events = res.body,
         res => this.onError(res.json)
        );
    }

    loadSpeakers() {
        this.loadSpeaker(78);    // Stephan
    }

    loadSpeaker(id: number) {
        this.speakerService.find(id).subscribe(
            res => this.speakers.push(res.body),
            res => this.onError(res.json)
        );
    }

    getEvent(item: number) {
        if (this.events != null) {
            return this.events[item];
        } else {
            return null;
        }
    }

    private onError(error) {
        console.log(error);
    }
}
